import React from 'react';
import ReactPixel from 'react-facebook-pixel'
import Layout from '../components/Layout';
import {Zoom} from 'react-reveal';
import pafla from '../api/pafla'
import BlogCard from '../components/BlogCard';
import {Col, Row} from 'antd';
import styles from '../styles/blog.module.css'
import {IMAGE_URL} from '../helpers/constants';
import Banner from '../components/Banner';
import { Helmet } from "react-helmet";

class Blogs extends React.Component {

	constructor(props) {
	    super(props)

	    this.state = {
	        blogs: null,
	        imageURL: null
	    }
	}

	async componentDidMount() {
        
        ReactPixel.pageView()

        await pafla.get('/blogs')
        .then((response) => {
            this.setState({blogs: response.data.data})
        })
    }


    render() {
        return (
        	<Layout>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Blog - Pakistan Freelancers Association</title>
                </Helmet>
                <Banner
                    title="PAFLA Blog"
                    text="Empowering Pakistan's freelancers with expert insights, resources, and a supportive community – your gateway to freelance success, written by your fellow freelancers."
                    background={`${IMAGE_URL}home_banner_bg.png`}
                    image={`${IMAGE_URL}home_banner_img.png`}
                    width={[9, 14]}
                />
	            <section id="main">
	                <Row justify="start">
	                    <Col xs={{span: 22}} md={{span: 22}} style={{margin: '0 auto'}}>
	                        <Zoom left>
	                            <div className={`card ${styles.blog_items}`}>
	                                {this.state.blogs && 
                                        Object.entries(this.state.blogs).map(([key, blog], i) => {
                                            return (
                                                <BlogCard
                                                    key={blog.id}
                                                    title={blog.title}
                                                    text={`${blog.excerpt.substring(0, 100)}...`}
                                                    link={`blog/${blog.slug}/${blog.id}`}
                                                    image={`${process.env.REACT_APP_STORAGE_URL}/${blog.featured_image}`}
                                                    author={blog.author}
                                                    created_at={blog.created_at}
                                                />
                                            )
                                        })
                                    }

	                            </div>
	                        </Zoom>
	                    </Col>
	                </Row>
	            </section>
            </Layout>
        );
    }
}

export default Blogs;
        