import React from "react";
import Layout from "../components/Layout";
import BlogAuthor from "../components/BlogAuthor";
import BlogAuthorLarge from "../components/BlogAuthorLarge";
import pafla from "../api/pafla";
import ReactHtmlParser from "react-html-parser";
import styles from "../styles/blog.module.css";
import { Helmet } from "react-helmet";

class Blogs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            blog: null,
        };
    }

    async componentDidMount() {
        const { blogId } = this.props.match.params;

        await pafla.get(`/blogs/${blogId}`).then((response) => {
            this.setState({ blog: response.data.data });
        });
    }

    render() {
        const { blog } = this.state;
        return (
            <Layout>
                {blog && (
                        <div className={styles.singleBlog}>
                            <Helmet>
                                <meta charSet='utf-8' />
                                <title>
                                    {blog.title} - Pakistan Freelancers
                                    Association
                                </title>
                            </Helmet>
                            <h1 className={styles.blogTitle}>{blog.title}</h1>
                            <BlogAuthor
                                author={blog.author}
                                createdAt={blog.created_at}
                            />
                            <br />
                            {blog.featured_image && (
                                <img
                                className={styles.featuredImage}
                                    src={`${process.env.REACT_APP_STORAGE_URL}/${blog.featured_image}`}
                                    alt={blog.title.rendered}
                                />
                            )}
                            {ReactHtmlParser(blog.content)}

                            <BlogAuthorLarge author={blog.author} createdAt={blog.created_at} />
                        </div>
                    )}
            </Layout>
        );
    }
}

export default Blogs;
